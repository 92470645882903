var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  }, [_c('rect', {
    attrs: {
      "y": "11",
      "width": "24",
      "height": "2",
      "rx": "1"
    }
  }), _vm._v(" "), _c('rect', {
    attrs: {
      "y": "4",
      "width": "24",
      "height": "2",
      "rx": "1"
    }
  }), _vm._v(" "), _c('rect', {
    attrs: {
      "y": "18",
      "width": "24",
      "height": "2",
      "rx": "1"
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }